@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100vh;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.app .content {
  overflow: auto;
  font-family: "Source Sans Pro", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* login  */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: auto;
}

.login-container .login-subContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  max-width: 800px;
  width: 80%;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 1rem 2rem;
  flex-direction: column;
}

.login-container .login-subContainer .upper-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-container .login-subContainer .logo {
  max-width: 100px;
}
.login-container .login-subContainer .logo-txt {
  width: 135px;
}

.login-container .login-subContainer form {
  width: 80%;
}

.login-container .login-subContainer form .login-btn {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.login-btn svg {
  margin-left: 0.5rem;
}

.error {
  color: red;
  background-color: rgba(255, 0, 0, 0.242);
  padding: 0.5rem;
  border-radius: 5px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.elipsized-txt {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
